import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Img from "gatsby-image"
import MEETSHOOT001MP3 from "../assets/LiveFireBed.mp3"

const meetup2020_06_06Page = props => (
  <Layout>
    <SEO title="NAlocal512meetup20200606" />
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
      <h1>NAlocal512 meetup June 6th, 2020</h1>
      <h2>June 6th, 2020 - 2pm</h2>
      <p><Img fluid={props.data.fullgroup20200606.childImageSharp.fluid} alt="" /></p>
      <p><Img fluid={props.data.fronttable20200606.childImageSharp.fluid} alt="" /></p>
      <p><Img fluid={props.data.NASanAntonioPartyBus.childImageSharp.fluid} alt="" /></p>
  </Layout>
)

export default meetup2020_06_06Page

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    fullgroup20200606: file(relativePath: { eq: "20200606-fullgroup.jpg" }) {
      ...fluidImage
    }
    fronttable20200606: file(relativePath: { eq: "20200606-frontTable.jpg" }) {
      ...fluidImage
    }
    NASanAntonioPartyBus: file(relativePath: { eq: "NASanAntonioPartyBus.jpg" }) {
      ...fluidImage
    }
  }
`
